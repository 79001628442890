#watermark {
  position: fixed;
  text-align: center;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#watermark span {
  position: relative;
  left: 0;
  top: 0;
  font-size: 10px;
}

#watermark img {
  width: 50px;
  height: auto;
  position: relative;
  left: 0;
  top: 5px;
  margin-left: 2px;
}
