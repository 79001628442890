input[type=radio],
input.radio {
  margin-right: 30px;
}

.radio {
    display: inline;
}

fieldset {
    border: 1px solid rgba(7,84,140,0.9);
    border-radius: 5px;
    padding: 15px;
}

.getGpsButton {
    margin-top: 30px;
}

input[readOnly] {
    border:0;
    font-weight: bold;
}

.all-center {
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
}

.datafield {
    margin-bottom: 10px;
}

#location-accuracy-green {
    color: green;
}

#location-accuracy-yellow {
    color: rgb(207, 207, 0);
}

#location-accuracy-red {
    color: red;
}

.button-area {
    text-align: center;
}

.button-view {
    align-content: center;
    text-align: center;
    margin: 1px;
    font-size: 20px !important;
    width: 200px;
    text-transform: none;
    background-color: rgba(7,84,140,0.5) !important;
    border-color: rgba(7,84,140,0.5) !important;
    padding: 0 !important;
}

.button-view:disabled {
    background-color: rgb(7,84,140,1) !important;
    opacity: 1;
}

#load-data-modal {
    position: fixed;
    width: 90%;
    height: 90%;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
    overflow: scroll;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

#accuracy-modal {
    position: fixed;
    width: 255px;
    border: 1px rgba(7,84,140,0.9) solid;
    top: 50%;
    left: 50%;
    text-align: center;
    font-size: 20px;
    padding: 15px;
    font-weight: bold;
    background: white;
    transform: translate(-50%, -50%);
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    font-size: 10px;
}

#top-info {
    position: fixed;
    top: 4px;
    left: 45px;
    margin-right: 50px;
    color: white;
    z-index: 3;
}

#top-info div {
    display: inline;
}

.macadam-distance-info {
    font-size: 30px;
    line-height: 25px;
    display: inline-block;
    padding-left: 3px;
    padding-right: 3px;
}

.borders {
    border: 1px rgba(7,84,140,0.9) solid;
    border-radius: 5px;
    padding: 4px;
    margin-bottom: 2px;
}

.label-icon {
    margin-bottom: 2px;
    margin-right: 2px;
    height: 50px;
    width: auto;
    -webkit-filter: invert(100%) invert(21%) sepia(59%) saturate(2645%) hue-rotate(186deg) brightness(94%) contrast(101%);
    filter: invert(100%) invert(21%) sepia(59%) saturate(2645%) hue-rotate(186deg) brightness(94%) contrast(101%);
}

#start-button {
    border: 6px solid #00FF00;
    line-height: 0;
}

#end-button {
    border: 6px solid #FF0000;
    line-height: 0;
}

.delete-button {
    border: 3px solid #FF0000;
    line-height: 0;
}

.spade {
    margin-left: 20px;
}

.small {
    font-size: 2vh;
}

.header-icon {
    vertical-align: middle;
    height: 40px;
}

input[type="email"], input[type="number"],
input[type="tel"], input[type="text"], select {
    height: 8vh !important;
    font-size: 4vh !important;
}

label {
    font-size: 3vh !important;
}

.radio-container {
    display: inline-block;
    position: relative;
    padding-left: 32px;
    margin-right: 25px;
    cursor: pointer;
    font-size: 24px !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
    background-color: rgba(7,84,140,0.9);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.radio-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.radio-container .checkmark:after {
    left: 10px;
    top: 2px;
    width: 10px;
    height: 20px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

button {
    height: 10vh !important;
    max-height: 50px;
    font-size: 15px !important;
    padding: 0 4vh !important;
}

.column {
    margin-bottom: 25px;
}

#count-select {
    width: 15vh !important;
}

@media (max-width: 1049px) {
    .button-view {
      width: 200px !important;
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .macadam-distance-info {
        font-size: calc(15px + 1vw) !important;
    }
}

@media (max-width: 700px) {
    button {
        height: 15vw !important;
        padding: 0 4vw !important;
    }
}
